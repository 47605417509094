//import { createRouter, createWebHashHistory,RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginLayout from '@/layouts/LoginLayout.vue';
import BasicLayout from '@/layouts/BasicLayout.vue';
import sy from './sy';
import login from './login';
import register from './register';
import feedback from './feedback';
import help from './help';
import news from './news';
import order from './order';
import product from './product';
import user from './user';
import user2 from './user2';
import whiteList from './white-list';
import activity from './activity';
import Store from '@/store';

const routes: Array<RouteRecordRaw> = [
 {
   path: '/',
   name: '官网',
   component: LoginLayout,
   redirect: '/index',
   children: [sy],
 },
 
  {
    path: '/indexs',
    component: BasicLayout,
    redirect: '/indexs',
    name: '首页',
    meta: { name: '首页' },
    children: [
      {
        path: '',
        name: '首页',
        component: () => import('../views/index/index.vue'),
        meta: { name: '首页', hideBreadcrumb: true, tab: 'index' },
      },
      {
        path: 'afterSales',
        name: '售后',
        component: () =>
          import('../views/user/subpage/AfterSales.vue'),
        meta: { name: '售后', hideBreadcrumb: true },
      },
     // product,
     // order,
      //news,
     // help,
     // feedback,
     // user,
      user2,
      //activity,
    ],
  },
  
  {
    path: '/product',
    name: '商品列表',
    component: BasicLayout,
    redirect: '/product/search',
    children: [product],
  },
  {
    path: '/order',
    name: '订单',
    component: BasicLayout,
    redirect: '/order/cart',
    children: [order],
  },
  {
    path: '/user',
    name: '用户',
    component: BasicLayout,
    redirect: '/user/sbindex',
    children: [user],
  },
  {
    path: '/helpCenter',
    name: '帮助中心',
    component: BasicLayout,
    redirect: '/helpCenter',
    children: [help],
  },
  {
    path: '/news',
    name: '新闻',
    component: BasicLayout,
    redirect: '/news/list',
    children: [news],
  },
  {
    path: '/activity',
    name: '活动',
    component: BasicLayout,
    redirect: '/activity/list',
    children: [activity],
  },
  {
    path: '/feedback',
    name: '意见反馈',
    component: BasicLayout,
    redirect: '/feedback/list',
    children: [feedback],
  },
  
  
  
  {
    path: '/login',
    name: '登录',
    component: LoginLayout,
    redirect: '/login/index',
    children: [login],
  },

  {
    path: '/register',
    name: '注册',
    component: LoginLayout,
    redirect: '/register/index',
    children: [register],
  },
  {
    path: '/reset',
    name: '重置密码',
    component: LoginLayout,
    redirect: '/reset/index',
    children: [
      {
        path: 'index',
        name: '重置密码',
        component: () => import(/* webpackChunkName: "reset" */ '../views/login/reset.vue'),
        meta: { name: '重置密码' },
      },
    ],
  },
  {
    path: '/white-list',
    name: '白名单页面',
    component: LoginLayout,
    redirect: '/white-list/article',
    children: [whiteList],
  },
  {
    path: '/:path(.*)*',
    name: 'ErrorPage',
    component: LoginLayout,
    meta: {
      title: 'ErrorPage',
      hideBreadcrumb: true,
    },
    children: [
      {
        path: '/:path(.*)*',
        name: 'ErrorPageSon',
        component: () => import('@/views/exception/404.vue'),
        meta: {
          title: 'ErrorPage',
          hideBreadcrumb: true,
        },
      },
    ],
  },
];

const router = createRouter({
  //history: createWebHashHistory(''),
  history:createWebHistory(),
  routes,
});

const WHITE_PAGE_LIST = [
  '/login/index',
  '/register/index',
  '/register/step-1',
  '/register/step-3',
  '/reset/index',
  '/'
];

router.beforeEach(async (to, _from, next) => {
  const url = window.location.href;
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const token = searchParams.get('token') as string;
  if (token && !Store.state.token) {
    await Store.dispatch('initData', token);
    next();
    return;
  }

  if (WHITE_PAGE_LIST.indexOf(to.path) >= 0 || to.path.indexOf('white-list') >= 0) {
    next();
    return;
  }
  if (
    (Store.state.regStatus || {}).step === null ||
    (Store.state.regStatus || {}).step === undefined
  ) {
    await Store.dispatch('apiGetRegStatusFn');
  }

  if ((Store.state.regStatus || {}).step === 0) {
    next({ path: '/register/step-1', replace: true });
    return;
  } else if ((Store.state.regStatus || {}).step === 1) {
    next();
    return;
  } else {
    next({ path: '/register/step-3', replace: true });
    return;
  }
});

export default router;
