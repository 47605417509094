import PageLayout from '@/layouts/PageLayout.vue';
const order = {
  path: '',
  redirect: '/order/cart',
  component: PageLayout,
  children: [
    {
      path: 'cart',
      name: '购物车',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/Cart.vue'),
      meta: { name: '购物车', background: '#fff' },
    },
    {
      path: 'cartNb',
      name: '牛币商城购物车',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/CartNb.vue'),
      meta: { name: '牛币商城购物车', background: '#fff' },
    },
    {
      path: 'place-order',
      name: '下订单 - 请核对订单信息',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/PlaceOrder.vue'),
      meta: { name: '下订单 - 请核对订单信息', background: '#fff' },
    },
    {
      path: 'place-order2',
      name: '下订单 - 请核对订单信息2',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/PlaceOrder2.vue'),
      meta: { name: '下订单 - 请核对订单信息', background: '#fff' },
    },
    {
      path: 'place-order-nb',
      name: '牛币商城下订单 - 请核对订单信息',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/PlaceOrderNb.vue'),
      meta: { name: '牛币商城下订单 - 请核对订单信息', background: '#fff' },
    },
    {
      path: 'pay',
      name: '支付订单',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/Pay.vue'),
      meta: { name: '支付订单', background: '#fff' },
    },
    {
      path: 'pay-result',
      name: '支付成功',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/PayResult.vue'),
      meta: { name: '支付成功', background: '#fff' },
    },
    {
      path: 'logistics',
      name: '物流详情',
      component: () => import(/* webpackChunkName: "order" */ '../views/order/Logistics.vue'),
      meta: { name: '物流详情', hideBreadcrumb: true, background: '#fff' },
    },
  ],
};
export default order;
