import axios from '../axios';
import * as UserModel from '../model/user';
export default class User {
  /**
   * 用户登录
   * @param data
   * @return Promise
   */
  public static async login(data: UserModel.UserLoginItem): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/login',
      data,
    });
  }

  /**
   * 用户退出
   * @param data
   * @return Promise
   */
  public static async apiUserLoginout(data: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/user_loginout',
      data,
      ...option,
    });
  }

  /**
   * 修改密码
   * @param data
   * @returns
   */
  public static async resetPassword(data: UserModel.ResetPassModel): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/resetpwd',
      data,
    });
  }

  /**
   * 发送验证码
   * @param data
   * @returns
   */
  public static async apiSendSms(data: UserModel.ApiSendSmsModel, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/send_sms',
      //type 1：注册短信 2：修改密码短信 3：绑定子账号 4：短信登录
      data,
      ...option,
    });
  }

  /**
   * 用户注册
   * @param data
   * @returns
   */
  public static async apiRegister(
    data: UserModel.ApiRegisterModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/register',
      //type 1：注册短信 2：修改密码短信 3：绑定子账号 4：短信登录
      data,
      ...option,
    });
  }

  /**
   * 获取注册信息
   * @param data
   * @returns
   */
  public static async apiGetRegInfo(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/reg_info',
      params,
      ...option,
    });
  }

  /**
   * 修改注册信息
   * @param data
   * @returns
   */
  public static async apiPostRegInfo(
    data: UserModel.ApiPostRegInfoModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/reg_info',
      data,
      ...option,
    });
  }

  /**
   * 我的资质
   * @returns
   */
  public static async getMyQualified(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_credentials',
      params,
      ...option,
    });
  }

  /**
   *
   * @returns 商家资质
   */
  public static async getBusinessQualified(params?: any, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_shop_qualifications',
      params,
      ...option,
    });
  }
  /**
   * 厂检报告
   * @returns
   */
  public static async getInspectList(params: UserModel.GetInspectRequestModel): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_factory_report',
      params,
    });
  }
  /**
   * 首营资料
   * @param params
   * @returns
   */
  public static async getLicenseList(
    params: UserModel.GetRequestModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_datum',
      params,
      ...option,
    });
  }
  /**
   * 消息角标
   * @returns
   */
  public static async getMessageBedge(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_message_info',
    });
  }
  /**
   * 消息列表
   * @param params
   * @returns
   */
  public static async getMessageList(params: UserModel.GetMessageModel): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_message',
      params,
    });
  }
  /**
   * 一键已读
   * @param msgType
   * @returns
   */
  public static async readMessageAll(msgType: number, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/msg_all_read',
      data: {
        msgType,
      },
      ...option,
    });
  }
  /**
   * 添加优惠券
   * @param couponCode
   * @returns
   */
  public static async addCoupon(couponCode: string, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/check_coupon',
      data: {
        coupon_code: couponCode,
      },
      ...option,
    });
  }
  /**
   * 可领取使用优惠券列表
   * @returns
   */
  public static async getDrawCouponList(params?: any, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/coupon_draw_list',
      params,
      ...option,
    });
  }
  /**
   * 领取优惠券
   * @returns
   */
  public static async drawCoupon(couponId: number, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/draw_coupon',
      data: {
        coupon_id: couponId,
      },
      ...option,
    });
  }
  /**
   * 优惠券列表
   * @param status 	2:已使用 3已过期
   * @returns
   */
  public static async getUserCouponList(status: number, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_coupon',
      params: {
        status,
      },
      ...option,
    });
  }
  /**
   * 获取余额日志
   * @param params
   * @returns
   */
  public static async getMoneyLogList(
    params: UserModel.MoneyLogModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/getMoneyLog',
      params,
      ...option,
    });
  }
  /**
   * 获取牛币日志
   * @param params
   * @returns
   */
  public static async apiGetScoreLog(params: UserModel.ApiGetScoreLogModel): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/getScoreLog',
      params,
    });
  }

  /**
   * 获取用户信息
   * @returns
   */
  public static async apiMyInfo(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/my_info',
    });
  }

  // 意见反馈
  public static async apiPostFeedback(
    data: UserModel.ApiPostFeedbackModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/feedback',
      data,
      ...option,
    });
  }

  // 获取审核状态
  public static async apiGetRegStatus(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/reg_status',
      params,
      ...option,
    });
  }

  // 充值
  public static async apiPostRecharge(
    data: UserModel.ApiPostRechargeModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/recharge',
      data,
      ...option,
    });
  }

  // 充值查询
  public static async apiGetRechargeInfo(
    data: UserModel.ApiGetRechargeInfoModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/recharge_info',
      params: data,
      ...option,
    });
  }

  public static async apiGetRechargePayList(data: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/recharge_pay_list',
      params: data,
      ...option,
    });
  }

  public static async apiPostRechargeCreatePay(
    data: UserModel.ApiPostRechargeCreatePayModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/recharge_create_pay',
      data: data,
      ...option,
    });
  }

  public static async apiGetActivityBuyPayList(data: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/pay_type_list',
      params: data,
      ...option,
    });
  }

  public static async apiPostActivityCreatePay(
    data: UserModel.ApiPostRechargeCreatePayModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/activity_create_pay',
      data: data,
      ...option,
    });
  }

  public static async apiGetActivityBuyInfo(
    data: UserModel.ApiGetRechargeInfoModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/activity_buy_info',
      params: data,
      ...option,
    });
  }

  public static async apiGetCouponExchangeList(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/coupon_exchange_list',
      params: params,
      ...option,
    });
  }

  public static async apiPostCouponExchange(data: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/coupon_exchange',
      data: data,
      ...option,
    });
  }

  public static async apiGetUserStatus(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_status',
      params: params,
      ...option,
    });
  }

  public static async apiPostUserStatusApply(data: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/user_status_apply',
      data: data,
      ...option,
    });
  }
  public static async apiGetUserSonList(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/user_son_list',
      params: params,
      ...option,
    });
  }
  //切换账号
  public static async apiPostUserSwitchAccount(
    data: UserModel.ApiPostUserSwitchAccountModel
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/user_switch_account',
      data: data,
    });
  }
  //绑定子账号
  public static async apiPostUserBindSon(
    data: UserModel.apiPostUserBindSonModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/user_bind_son',
      data: data,
      ...option,
    });
  }
  //解除子账号
  public static async apiPostUserUnbind(data: UserModel.ApiPostUserUnbindModel): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/user_unbind',
      data: data,
    });
  }

  // 获取剩余牛币
  public static async apiGetMoneyPanel(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/money_panel',
    });
  }
}
