import PageLayout from '@/layouts/PageLayout.vue';
const product = {
  path: '',
  redirect: '/product/hot',
  component: PageLayout,
  name: '产品列表',
  meta: { name: '产品列表' },
  children: [
    {
      path: 'hot',
      name: '热销产品',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/HotProduct.vue'),
      meta: { name: '热销产品', type: 'hot', tab: 'hot' },
    },
    {
      path: 'search',
      name: '搜索结果页',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/Search.vue'),
      meta: { name: '搜索结果页' },
    },
    {
      path: 'soonProduct',
      name: '即将到货',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/SoonProduct.vue'),
      meta: { name: '即将到货' },
    },
    {
      path: 'discount',
      name: '特惠专区',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/DiscountProduct.vue'),
      meta: { name: '特惠专区', hideBreadcrumb: true, tab: 'discount' },
    },
    {
      path: 'seckillArea',
      name: '爆款秒杀',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/SeckillAreaProduct.vue'),
      meta: { name: '秒杀限购', hideBreadcrumb: true, tab: 'seckillArea' },
    },
    {
      path: 'ranking',
      name: '排行榜',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/RankingProduct.vue'),
      meta: { name: '排行榜', hideBreadcrumb: true, tab: 'ranking' },
    },
    {
      path: 'new',
      name: '新品推荐',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/NewProduct.vue'),
      meta: { name: '新品推荐', type: 'new', tab: 'new' },
    },
    {
      path: 'nbProduct',
      name: '牛币商城',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/NbProduct.vue'),
      meta: { name: '牛币商城', tab: 'nb_shop' },
    },
    {
      path: 'quick',
      name: '快速下单',
      component: () => import(/* webpackChunkName: "product" */ '../views/product/Quick.vue'),
      meta: { name: '快速下单', tab: 'quick' },
    },
    {
      path: 'detail',
      name: '商品详情',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/ProductDetail.vue'),
      meta: { name: '商品详情', hideBreadcrumb: true, background: '#fff' },
    },
    {
      path: 'nbDetail',
      name: '商品详情2',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/NbProductDetail.vue'),
      meta: { name: '商品详情', hideBreadcrumb: true, background: '#fff' },
    },
    {
      path: 'SpecialGoods',
      name: '特色专区',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/SpecialGoods.vue'),
      meta: { name: '特色专区' },
    },
    {
      path: 'MedicalGoods',
      name: '医保专区',
      component: () =>
        import(/* webpackChunkName: "product" */ '../views/product/MedicalGoods.vue'),
      meta: { name: '医保专区' },
    },
  ],
};
export default product;
