import PageLayout from '@/layouts/PageLayout.vue';
const login = {
  path: '',
  redirect: '/login/index',
  component: PageLayout,
  children: [
    {
      path: 'index',
      name: '官网首页',
      component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
      meta: { name: '官网首页' },
    },
  ],
};
export default login;
