import PageLayout from '@/layouts/PageLayout.vue';
const logins = {
  path: '',
  redirect: '/index',
  component: PageLayout,
  children: [
    {
      path: '',
      name: '官网',
      component: () => import('../views/white-list/index.vue'),
      meta: { name: '官网' },
    },
  ],
};
export default logins;
