const user = {
  path: '',
  redirect: '/user/sbindex',
  name: '我的青牛',
  meta: { name: '我的青牛' },
  component: () => import('../views/user/UserCenter.vue'),
  children: [
    {
      path: 'sbindex',
      name: '用户首页',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Index.vue'),
      meta: { name: '用户首页' },
    },
    {
      path: 'myorder',
      name: '我的订单',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/MyOrder.vue'),
      meta: { name: '我的订单' },
    },
    {
      path: 'myqualified',
      name: '我的资质',
      component: () =>
        import(/* webpackChunkName: "user" */ '../views/user/subpage/MyQualified.vue'),
      meta: { name: '我的资质' },
    },
    {
      path: 'businessqualified',
      name: '商家资质',
      component: () =>
        import(/* webpackChunkName: "user" */ '../views/user/subpage/BusinessQualified.vue'),
      meta: { name: '商家资质' },
    },
    {
      path: 'mycomment',
      name: '我的评价',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/MyComment.vue'),
      meta: { name: '我的评价' },
    },
    {
      path: 'inspect',
      name: '质检报告',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Inspect.vue'),
      meta: { name: '质检报告' },
    },
    {
      path: 'license',
      name: '首营资料',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/License.vue'),
      meta: { name: '首营资料' },
    },
    {
      path: 'message',
      name: '消息中心',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Message.vue'),
      meta: { name: '消息中心' },
    },
    {
      path: 'personal',
      name: '个人资料',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Personal.vue'),
      meta: { name: '个人资料' },
    },
    {
      path: 'password',
      name: '修改密码',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Password.vue'),
      meta: { name: '修改密码' },
    },
    {
      path: 'addCoupon',
      name: '添加优惠券',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/AddCoupon.vue'),
      meta: { name: '添加优惠券' },
    },
    {
      path: 'convert',
      name: '兑换抵用券',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Convert.vue'),
      meta: { name: '兑换抵用券' },
    },
    {
      path: 'recharge',
      name: '余额充值',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Recharge.vue'),
      meta: { name: '余额充值' },
    },
    {
      path: 'orderDetail',
      name: '订单详情',
      component: () =>
        import(/* webpackChunkName: "user" */ '../views/user/subpage/OrderDetail.vue'),
      meta: { name: '订单详情' },
    },
    {
      path: 'coupons',
      name: '优惠券列表',
      component: () => import(/* webpackChunkName: "user" */ '../views/user/subpage/Coupons.vue'),
      meta: { name: '优惠券列表' },
    },
    {
      path: 'after-sales-service',
      name: '售后服务',
      component: () =>
        import(/* webpackChunkName: "user" */ '../views/user/subpage/AfterSalesService.vue'),
      meta: { name: '售后服务' },
    },
  ],
};
export default user;
