
  import { defineComponent, computed, inject, ref } from 'vue';
  import { Back, ArrowUp } from '@element-plus/icons-vue';
  import getBasicData from '@/hooks/getBasicData';

  export default defineComponent({
    components: { Back, ArrowUp },
    emits: ['toTop'],
    setup(_, { emit }) {
      const $f: any = inject('$f');
      const $route: any = inject('$route');
      const $store: any = inject('$store');
      const show = ref(true);
      const getshow = () => {
        show.value = !show.value;
      };

      const { userInfo } = getBasicData();

      const areaName = computed(() => {
        return $f.safeData(userInfo.value, 'ywyId3_info.realName', '');
      });

      const areaTel = computed(() => {
        return $f.safeData(userInfo.value, 'ywyId3_info.tel', '');
      });

      const provName = computed(() => {
        return $f.safeData(userInfo.value, 'ywyId1_info.realName', '');
      });

      const provTel = computed(() => {
        return $f.safeData(userInfo.value, 'ywyId1_info.tel', '');
      });

      const kfInfo = computed(() => {
        return $f.safeData(userInfo.value, 'kf_info', {});
      });

      const cartCount = computed(() => {
        return $store.state.cartCount;
      });

      const toCart = () => {
        $route.push('/order/cart');
      };

      const toTop = () => {
        emit('toTop');
      };

      const goto = (url: string) => {
        $route.push(url);
      };
      




      return {
        cartCount,
        areaName,
        areaTel,
        provName,
        provTel,
        kfInfo,
        show,
        getshow,
        toCart,
        toTop,
        goto,
      };
    },
  });
